import { Controller } from '@hotwired/stimulus';
import ahoy from 'ahoy.js';
import { UAParser } from 'ua-parser-js';

function loadScript(source: string, async = true, type = 'text/javascript') {
  return new Promise((resolve, reject) => {
    try {
      const scriptElement = document.createElement('script');
      scriptElement.type = type;
      scriptElement.async = async;
      scriptElement.src = source;

      scriptElement.addEventListener('load', () => {
        resolve({ status: true });
      });

      scriptElement.addEventListener('error', () => {
        reject({
          status: false,
          message: `Failed to load the script ${source}`,
        });
      });

      document.head.append(scriptElement);
    } catch (error) {
      reject(error);
    }
  });
}

export default class extends Controller {
  static targets = ['player', 'modal'];
  static values = {
    playlist: String,
  };

  declare playlistValue: string;
  declare readonly hasPlaylistValue: boolean;
  declare readonly hasPlayerTarget: boolean;
  declare readonly playerTarget: HTMLDivElement;
  declare readonly hasModalTarget: boolean;
  declare readonly modalTarget: HTMLDivElement;

  player?: jwplayer.JWPlayer;

  async connect() {
    await loadScript('https://content.jwplatform.com/libraries/ReOllMIn.js');

    if (!this.hasModalTarget) {
      this.setupPlayer();
      this.setupAnalytics();
    }
  }

  modelWillShow() {
    console.log('show');
    this.setupPlayer();
  }

  modelWillHide() {
    this.player?.remove();
    console.log('hide');
  }

  setupPlayer() {
    this.player = jwplayer(this.playerTarget).setup({
      width: '100%',
      aspectratio: '16:9',
      playlist: this.playlistValue,
      ga: {
        label: 'mediaid',
        sendEnhancedEvents: true,
      },
    });
  }

  analyticMetadata(extra: Record<string, unknown> = {}) {
    const ua = new UAParser();

    const standard = {
      video: {
        id: this.player?.getPlaylistItem().mediaid,
        title: this.player?.getPlaylistItem().title,
      },
      at: this.player?.getPosition(),
      player: {
        ua: ua.getBrowser(),
        engine: ua.getEngine(),
        os: ua.getOS(),
        device: ua.getDevice(),
        ver: jwplayer.version.split('+')[0],
        width: this.player?.getWidth(),
        height: this.player?.getHeight(),
        volume: this.player?.getVolume(),
        muted: this.player?.getMute(),
        fullscreen: this.player?.getFullscreen(),
      },
    };

    return { ...standard, ...extra };
  }

  setupAnalytics() {
    if (!this.player) return;

    this.player.on('play', () => {
      ahoy.track('Video Play', this.analyticMetadata());
    });

    this.player.on('pause', () => {
      ahoy.track('Video Pause', this.analyticMetadata());
    });

    this.player.on('complete', () => {
      ahoy.track('Video Complete', this.analyticMetadata());
    });

    this.player.on('seek', () => {
      ahoy.track('Video Seek', this.analyticMetadata());
    });

    this.player.on('visualQuality', (event) => {
      ahoy.track(
        'Video Quality Change',
        this.analyticMetadata({
          quality: event.level,
          reason: event.reason,
        }),
      );
    });
  }

  disconnect() {
    this.player?.remove();
  }
}
